import React, { useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import styled, { ThemeProvider, DefaultTheme } from 'styled-components'
import { toCssPrefix, breakpoints, ProductCard, getStoredCity, City } from '@ecommerce/shared'
import { RemoveScroll } from 'react-remove-scroll'
import withPageTransition from '../components/withPageTransition'
import Layout from '../components/Layout'
import { PrehomePage } from '../types/PgPages'
import { BlockType, Block, BuildTimePromo, MlBanner } from '../graphql/contentfulTypes'
import { contentfulThemeToThemeExtension } from '../graphql/global/theme'
import { SectionContainer } from '../templates/home/styled'
import PromoCardLarge from '../components/PromoList/PromoCardLarge'
import { PromoCardWrapper } from '../components/PromoList'
import { Banner } from '../components/Banner'
import secrets from '../config/secrets'
import SliderWrapper from '../components/SliderWrapper'
import LocationSelector from '../components/LocationSelector'

const { COUNTRY, GATSBY_PREHOME_REDIRECT_URL } = secrets

const { cssPrefix, toPrefix } = toCssPrefix('PreHome__')

const PageWrapper = styled.div`
  max-wdith: 100%;
  overflow: hidden;
`

const PromoWrapper = styled.div`
  display: flex;
  overflow: hidden;
  .${cssPrefix} {
    &card {
      width: 310px;
    }
  }
  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &card {
        width: 367px;
      }
    }
  }
`

const ProductCardsList = styled.div`
  overflow: hidden;
  display: flex;
  .${cssPrefix} {
    &product-card {
      margin-right: 28px;
      width: 190px;
    }
  }
  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &product-card {
        width: 240px;
        height: 424px;
      }
    }
  }
`

type ContentfulProduct = {
  name: string
  sku: string
  images: { file: { url: string } }[]
}

function IndexPage({ data }: PrehomePage) {
  const city = getStoredCity()
  useEffect(() => {
    if (city?.slug) navigate(`/${city.slug}`)
    else if (GATSBY_PREHOME_REDIRECT_URL) {
      window.location.href = GATSBY_PREHOME_REDIRECT_URL
    }
  }, [])

  if (city?.slug) return null

  if (!data?.locationPage?.edges.length) return null
  const { blocks, theme: pageTheme } = data.locationPage.edges[0].node

  const themeExtension = pageTheme ? contentfulThemeToThemeExtension(pageTheme) : {}
  const theme = (currentTheme: DefaultTheme) => ({
    ...currentTheme,
    colors: { ...currentTheme.colors, ...themeExtension },
  })

  if (city?.slug || GATSBY_PREHOME_REDIRECT_URL) return null

  return (
    <ThemeProvider theme={theme}>
      <Layout
        title="Inicio"
        currentCity={
          { name: 'Cochabamba', slug: 'cochabamba', commerceLayer: { stockLocation: { name: 'LA PAZ' } } } as City
        }
        disableControls
      >
        <PageWrapper>
          {blocks?.map((block) => {
            switch (block.blockType) {
              case BlockType.PROMO_LARGE:
              case BlockType.PROMO_SMALL: {
                const { items } = block as Block<BuildTimePromo>
                return (
                  <SectionContainer key={block.name} className="promos-container" hasAltBackground>
                    <PromoWrapper>
                      {items.map((item) => {
                        return (
                          <PromoCardWrapper type="large">
                            <PromoCardLarge
                              data={{
                                ...item,
                                title: item.promoTitle,
                                description: item.promoDescription,
                                image: item.image.file.url,
                              }}
                              currencySymbol="Bo"
                              className={toPrefix('card')}
                            />
                          </PromoCardWrapper>
                        )
                      })}
                    </PromoWrapper>
                  </SectionContainer>
                )
              }
              case BlockType.BANNER_SLIDER: {
                if (!block.items.length) return null
                const banner = block.items[0] as MlBanner
                return <Banner {...banner} actionSlug={{ actionSlug: '#' }} country={COUNTRY} index={0} />
              }
              case BlockType.PRODUCT: {
                if (!block.items.length) return null
                return (
                  <SliderWrapper title={block.title ?? ''}>
                    <ProductCardsList>
                      {((block.items as unknown) as ContentfulProduct[]).map((item) => {
                        return item?.images?.length ? (
                          <div className={toPrefix('product-card')}>
                            <ProductCard
                              product={{
                                skuCode: item.sku,
                                image: item.images.length ? item.images[0].file.url : '',
                                title: item.name,
                                hasDiscount: false,
                                price: 0,
                                rawPrice: 0,
                              }}
                              type="responsive"
                              showAddButton={false}
                              showPrice={false}
                            />
                          </div>
                        ) : null
                      })}
                    </ProductCardsList>
                  </SliderWrapper>
                )
              }
              default:
                return null
            }
          })}
        </PageWrapper>
        <RemoveScroll>
          <LocationSelector onClose={() => ''} isPrehome />
        </RemoveScroll>
      </Layout>
    </ThemeProvider>
  )
}

export default withPageTransition(IndexPage)

export const query = graphql`
  query PrehomeQuery($prehomeId: String!) {
    locationPage: allContentfulPgPage(filter: { contentful_id: { eq: $prehomeId } }) {
      edges {
        node {
          title
          theme {
            bodyBackground
            bodyBackgroundAlt
            bodyText
            brandActive
            brandDefault
            brandHover
            brandSelected
            brandTextActive
            brandTextDefault
            brandTextHover
            brandTextSelected
          }
          blocks {
            name
            contentful_id
            blockType
            title
            items {
              ... on ContentfulMlBanner {
                actionSlug {
                  actionSlug
                }
                backgroundImageDesktop {
                  file {
                    url
                  }
                }
                backgroundImageMobile {
                  file {
                    url
                  }
                }
                backgroundImageTablet {
                  file {
                    url
                  }
                }
                buttonColor
                buttonText {
                  json
                }
                buttonType
                dropdownButtonText {
                  json
                }
                dropdownButtonPosition
                dropdownContent {
                  json
                }
                layout
                name
                productImagesDesktop {
                  file {
                    url
                  }
                }
                productImageMobile {
                  file {
                    url
                  }
                }
                preTitle {
                  json
                }
                subtitle {
                  json
                }
                bannerTitle: title {
                  json
                }
                hashtag {
                  json
                }
                hashtagColor
                preTitleColor
                productImageTablet {
                  file {
                    url
                  }
                }
                subtitleColor
                titleColor
                textAlignment
                contentAlignment
                promotionalCode {
                  json
                }
                promotionalCodeColor
                promotionalCodeBackgroundColor
                alcoholicBeverageBlock
                discount
                discountType
                discountLabelText
                discountLabelColor
                googleTagManagerEventType
                googleTagManagerEventValue
              }
              ... on ContentfulMlCardPromo {
                id
                promoTitle
                promoSubtitle
                promoDescription
                actionSlugLong {
                  actionSlugLong
                }
                titleType
                googleTagManagerEventType
                googleTagManagerEventValue
                image {
                  file {
                    url
                  }
                }
              }
              ... on ContentfulProduct {
                id
                name
                sku
              }
              ... on ContentfulBundle {
                id
                name
                sku
              }
            }
          }
        }
      }
    }
  }
`
