import styled from 'styled-components'
import { breakpoints, ZIndex } from '@ecommerce/shared'

export const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${(props) => props.theme.colors.medium};
  height: 0;

  .search-bar {
    min-width: 76%;
    height: 44px;
    position: relative;
    top: -20px;
    z-index: ${ZIndex.low};
    overflow: hidden;
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .search-bar {
      min-width: 40%;
    }
  }
  @media (${breakpoints.desktop.min}) {
    background-color: ${(props) => props.theme.colors.medium};
    height: 40px;

    .search-bar {
      min-width: 500px;
    }
  }
`

export const SectionContainer = styled.div<{
  hasAltBackground?: boolean
  hasSearchboxPadding?: boolean
  background?: string
}>`
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background: ${({ theme, background }) => (background ? `url(${background})` : theme.colors.bodyBackground.default)};
  background-position-x: center;
  background-size: cover;
  background-position-y: bottom;
  position: relative;

  @media (${breakpoints.desktop.min}) {
    background-color: ${({ hasAltBackground, theme }) =>
      hasAltBackground ? theme.colors.bodyBackground.alt : theme.colors.bodyBackground.default};
    padding: ${({ hasSearchboxPadding }) => (hasSearchboxPadding ? `40px 100px 20px 100px` : `20px 100px`)};
  }
`
